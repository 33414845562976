import { Fragment, useEffect, useState } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { DynamicKeys } from "../../components/convertion/DynamicKeys";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks";
import { useLocation, useParams } from "react-router-dom";
import LoaderCard from "../../components/cards/LoaderCard";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  Chip,
  useTheme,
  alpha,
} from "@mui/material";
import ZoomableImage from "../../components/image-viewer/ZoomableImage";
import success from "../../assets/svgs/success.svg";
import failed from "../../assets/svgs/failed.svg";
import notApplicable from "../../assets/svgs/not_applicable.svg";

export const DeletedUserDocumentStatusDetailsScreen = () => {
  const { uid } = useParams();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();

  const theme = useTheme();
  const location = useLocation();

  const [statusDetails, setStatusDetails] = useState(null);
  const [statusDetails2, setStatusDetails2] = useState(null);
  const [documents, setDocumants] = useState({
    imageBackFile: "",
    imageFrontFile: "",
  });
  const [overAll, setOverAll] = useState(null);
  const [verificationDetails, setVerificationDetails] = useState(null);
  const [extractionFailed, setExtranctionFailed] = useState(false);
  const [viewMoreSelected, setViewMoreSelected] = useState(false);
  const [documentAndImageData, setDocumentAndImageData] = useState([]);

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getStatusDetails = async () => {
    api
      .get(`${urls.deletedCustomerIdVerificationDetail}/${uid}`)
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res.data.data.response);
        setDocumants(res.data.data.request);

        // microblink response
        setStatusDetails(res.data.data.response.data);

        // regula response
        if (!res.data.data.response?.data) {
          setStatusDetails2(res.data.data.response);
        }

        if (res.data.data.response?.data) {
          if (res.data.data.response?.data?.overallFraudCheck) {
            setOverAll(res.data.data.response?.data?.overallFraudCheck);
          }

          if (
            res.data.data.response?.data?.processingStatus ===
            "EXTRACTION_FAILED"
          ) {
            setExtranctionFailed(true);
          }
          if (res.data.data.response?.data) {
            //map data
            const verificationArray = [];
            if (res.data.data.response?.data?.dataCheck) {
              // DATA
              let dataCheck = res.data.data.response?.data?.dataCheck;
              let innerItems = [];

              if (dataCheck.logicCheck) {
                let level2Items = [];
                if (dataCheck.logicCheck?.mrzCheck) {
                  level2Items.push({
                    title: "mrz Check",
                    status:
                      dataCheck.logicCheck?.mrzCheck?.overall === "PASS"
                        ? 1
                        : dataCheck.logicCheck?.mrzCheck?.overall === "FAIL"
                        ? 2
                        : 3,
                  });
                }
                innerItems.push({
                  title: "Data Logic",
                  status:
                    dataCheck.logicCheck?.overall?.result === "PASS"
                      ? 1
                      : dataCheck.logicCheck?.overall?.result === "FAIL"
                      ? 2
                      : 3,
                  items: level2Items,
                });
              }
              if (dataCheck.matchCheck) {
                innerItems.push({
                  title: "Data Match",
                  status:
                    dataCheck.matchCheck?.overall?.result === "PASS"
                      ? 1
                      : dataCheck.matchCheck?.overall?.result === "FAIL"
                      ? 2
                      : 3,
                  items: [],
                });
              }

              if (dataCheck.formatCheck) {
                innerItems.push({
                  title: "Data Format",
                  status:
                    dataCheck.formatCheck?.overall?.result === "PASS"
                      ? 1
                      : dataCheck.formatCheck?.overall?.result === "FAIL"
                      ? 2
                      : 3,
                  items: [],
                });
              }

              if (dataCheck.barcodeAnomalyCheck) {
                innerItems.push({
                  title: "Barcode Anomaly",
                  status:
                    dataCheck.barcodeAnomalyCheck?.overall?.result === "PASS"
                      ? 1
                      : dataCheck.barcodeAnomalyCheck?.overall?.result ===
                        "FAIL"
                      ? 2
                      : 3,
                  items: [],
                });
              }

              if (dataCheck.suspiciousDataCheck) {
                innerItems.push({
                  title: "Suspicious Data Check",
                  status:
                    dataCheck.suspiciousDataCheck?.overall?.result === "PASS"
                      ? 1
                      : dataCheck.suspiciousDataCheck?.overall?.result ===
                        "FAIL"
                      ? 2
                      : 3,
                  items: [],
                });
              }

              verificationArray.push({
                title: "Data Check",
                status:
                  dataCheck.overall?.result === "PASS"
                    ? 1
                    : dataCheck.overall?.result === "FAIL"
                    ? 2
                    : 3,
                items: innerItems,
              });
            }

            if (res.data.data.response?.data?.documentLivenessCheck) {
              // LIVENESS
              let liveness =
                res.data.data.response?.data?.documentLivenessCheck;
              let innerItems = [];
              if (liveness.screenCheck) {
                innerItems.push({
                  title: "Screen Detection",
                  status:
                    liveness.screenCheck?.result === "PASS"
                      ? 1
                      : liveness.screenCheck?.result === "FAIL"
                      ? 2
                      : 3,
                });
              }

              if (liveness.photocopyCheck) {
                innerItems.push({
                  title: "Photo Copy",
                  status:
                    liveness.photocopyCheck?.result === "PASS"
                      ? 1
                      : liveness.photocopyCheck?.result === "FAIL"
                      ? 2
                      : 3,
                });
              }

              if (liveness.handPresenceCheck) {
                innerItems.push({
                  title: "Hand Presence",
                  status:
                    liveness.handPresenceCheck === "PASS"
                      ? 1
                      : liveness.handPresenceCheck === "FAIL"
                      ? 2
                      : 3,
                });
              }

              verificationArray.push({
                title: "Document Liveness Check",
                status:
                  liveness.overall === "PASS"
                    ? 1
                    : liveness.overall === "FAIL"
                    ? 2
                    : 3,
                items: innerItems,
              });
            }

            if (res.data.data.response?.data?.visualCheck) {
              // VISUAL
              let visualCheck = res.data.data.response?.data?.visualCheck;
              let innerItems = [];
              if (visualCheck.securityFeatures) {
                innerItems.push({
                  title: "Security Features",
                  status:
                    visualCheck.securityFeatures?.overall?.result === "PASS"
                      ? 1
                      : visualCheck.securityFeatures?.overall?.result === "FAIL"
                      ? 2
                      : 3,
                });
              }

              if (visualCheck.photoForgeryCheck) {
                innerItems.push({
                  title: "Photo Forgery",
                  status:
                    visualCheck.photoForgeryCheck === "PASS"
                      ? 1
                      : visualCheck.photoForgeryCheck === "FAIL"
                      ? 2
                      : 3,
                });
              }

              verificationArray.push({
                title: "Visual Check",
                status:
                  visualCheck.overall === "PASS"
                    ? 1
                    : visualCheck.overall === "FAIL"
                    ? 2
                    : 3,
                items: innerItems,
              });
            }

            let docAndImageDetail = [];
            if (res.data.data.response?.data?.documentValidityCheck) {
              //documentValidityCheck
              let documentValidityCheck =
                res.data.data.response?.data?.documentValidityCheck;

              verificationArray.push({
                title: "Document Validity Check",
                status:
                  documentValidityCheck.expiredCheck === "PASS"
                    ? 1
                    : documentValidityCheck.expiredCheck === "FAIL"
                    ? 2
                    : 3,
                items: [],
              });
            }

            if (res.data.data.response?.data?.imageQualityCheck) {
              //imageQualityCheck
              let imageQualityCheck =
                res.data.data.response?.data?.imageQualityCheck;

              verificationArray.push({
                title: "Image Quality Check",
                status:
                  imageQualityCheck.blurCheck === "PASS"
                    ? 1
                    : imageQualityCheck.blurCheck === "FAIL"
                    ? 2
                    : 3,
                items: [],
              });
            }
            console.log(verificationArray);
            setVerificationDetails(verificationArray);
            setDocumentAndImageData(docAndImageDetail);
          }
        } else {
          if (res.data.data.response?.doc_overall_status) {
            setOverAll({
              result: {
                result:
                  res.data.data.response?.doc_overall_status?.overall_status ===
                  1
                    ? "PASS"
                    : res.data.data.response?.doc_overall_status
                        ?.overall_status === 0
                    ? "FAIL"
                    : "NOT_PERFORMED",
              },
            });
            if (
              ![0, 1].includes(
                res.data.data.response?.doc_overall_status?.overall_status
              )
            ) {
              setExtranctionFailed(false);
            }
          }
          const verificationArray = [];

          const docType =
            res.data.data.response?.doc_overall_status?.details_optical
              ?.doc_type;
          if (docType !== null) {
            verificationArray.push({
              title: "Document Recognized",
              status: docType === 1 ? 1 : docType === 0 ? 2 : 3,
              items: [],
            });
          }

          const expiry =
            res.data.data.response?.doc_overall_status?.details_optical?.expiry;
          if (expiry !== null) {
            verificationArray.push({
              title: "Document Validity",
              status: expiry === 1 ? 1 : expiry === 0 ? 2 : 3,
              items: [],
            });
          }

          const text =
            res.data.data.response?.doc_overall_status?.details_optical?.text;
          if (text !== null) {
            verificationArray.push({
              title: "Text Fields Validation",
              status: text === 1 ? 1 : text === 0 ? 2 : 3,
              items: [],
            });
          }

          const security =
            res.data.data.response?.doc_overall_status?.details_optical
              ?.security;
          if (security !== null) {
            verificationArray.push({
              title: "Authenticity",
              status: security === 1 ? 1 : security === 0 ? 2 : 3,
              items: [],
            });
          }

          const imageQA =
            res.data.data.response?.doc_overall_status?.details_optical
              ?.image_qa;
          if (imageQA !== null) {
            const innerItems = [];

            const focus = res.data.data.response?.doc_image_quality?.find(
              (x) => x.type === 1
            );
            if (focus !== null) {
              innerItems.push({
                title: "Focus",
                status: focus.result === 1 ? 1 : focus?.result === 0 ? 2 : 3,
              });
            }

            const glares = res.data.data.response?.doc_image_quality?.find(
              (x) => x.type === 0
            );
            if (glares !== null) {
              innerItems.push({
                title: "Glares",
                status: glares.result === 1 ? 1 : glares?.result === 0 ? 2 : 3,
              });
            }

            const portrait = res.data.data.response?.doc_image_quality?.find(
              (x) => x.type === 7
            );
            if (portrait !== null) {
              innerItems.push({
                title: "Portrait",
                status:
                  portrait.result === 1 ? 1 : portrait?.result === 0 ? 2 : 3,
              });
            }

            const bounds = res.data.data.response?.doc_image_quality?.find(
              (x) => x.type === 5
            );
            if (bounds !== null) {
              innerItems.push({
                title: "Bounds",
                status: bounds.result === 1 ? 1 : bounds?.result === 0 ? 2 : 3,
              });
            }

            const perspective = res.data.data.response?.doc_image_quality?.find(
              (x) => x.type === 4
            );
            if (bounds !== null) {
              innerItems.push({
                title: "Perspective Angle",
                status:
                  perspective.result === 1
                    ? 1
                    : perspective?.result === 0
                    ? 2
                    : 3,
              });
            }

            const resolution = res.data.data.response?.doc_image_quality?.find(
              (x) => x.type === 2
            );
            if (resolution !== null) {
              innerItems.push({
                title: "Resolution",
                status:
                  resolution.result === 1
                    ? 1
                    : resolution?.result === 0
                    ? 2
                    : 3,
              });
            }

            verificationArray.push({
              title: "Image Quality",
              status: imageQA === 1 ? 1 : imageQA === 0 ? 2 : 3,
              items: innerItems,
            });
          }

          console.log(verificationArray);
          setVerificationDetails(verificationArray);
        }
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  //useEffect
  useEffect(() => {
    getStatusDetails();
  }, []);

  return (
    <Fragment>
      <ActionBar header={"ID Verification Details"} showBackButton={true} />
      <PageStackLayout>
        <Typography variant="h1">{"ID Verification Details"}</Typography>
        {isLoading && <LoaderCard />}
        {!isLoading && statusDetails !== null && (
          <Stack
            direction={"column"}
            sx={{
              bgcolor: theme.palette.common.white,
              borderRadius: 3,
              p: 3,
              gap: 2,
            }}
          >
            <Grid container spacing={4}>
              <Grid item md={3}>
                <Stack direction={"column"} spacing={2}>
                  <Stack
                    direction={"column"}
                    sx={{
                      bgcolor: theme.palette.common.lightGreyBg,
                      borderRadius: 2,
                      p: 2,
                    }}
                    spacing={2}
                  >
                    <ZoomableImage
                      border={"4px"}
                      src={documents.imageFrontFile}
                    />
                    {!statusDetails2 && (
                      <ZoomableImage
                        border={"4px"}
                        src={documents.imageBackFile}
                      />
                    )}
                  </Stack>
                  {extractionFailed && (
                    <Stack
                      sx={{
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Customize shadow as needed
                        borderRadius: "8px", // Optional: Add border radius for rounded corners
                        py: 1, // Optional: Add padding to the box
                        pl: 2,
                        pr: 2,
                      }}
                      spacing={2}
                      direction={"column"}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          pr: "3px",
                        }}
                      >
                        Extraction Failed
                      </Typography>
                    </Stack>
                  )}
                  {overAll !== null && (
                    <Stack
                      sx={{
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Customize shadow as needed
                        borderRadius: "8px", // Optional: Add border radius for rounded corners
                        py: 2, // Optional: Add padding to the box
                        pl: 2,
                        pr: 2,
                      }}
                      spacing={2}
                      direction={"column"}
                    >
                      <Stack
                        spacing={1}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                        >
                          Verification Status
                        </Typography>
                        {statusDetails2 ? (
                          <>
                            {overAll.result?.result === "PASS" && (
                              <Chip
                                label="Pass"
                                sx={{
                                  height: "28px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: theme.palette.passGreen.main,
                                  bgcolor: alpha(
                                    theme.palette.passGreen.main,
                                    0.2
                                  ),
                                }}
                              />
                            )}
                            {overAll.result?.result === "FAIL" && (
                              <Chip
                                label="Failed"
                                sx={{
                                  height: "28px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: theme.palette.status.rejected,
                                  bgcolor: alpha(
                                    theme.palette.status.rejected,
                                    0.2
                                  ),
                                }}
                              />
                            )}
                            {overAll.result?.result === "NOT_PERFORMED" && (
                              <Chip
                                label="Not Performed"
                                sx={{
                                  height: "28px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <Stack
                            sx={{
                              border: 1,
                              borderColor:
                                overAll.result?.result === "PASS"
                                  ? theme.palette.status.approved
                                  : theme.palette.status.rejected,
                              borderRadius: 2,
                              p: 1,
                              bgcolor:
                                overAll.result?.result === "PASS"
                                  ? alpha(theme.palette.status.approved, 0.1)
                                  : alpha(theme.palette.status.rejected, 0.1),
                            }}
                            direction={"row"}
                            spacing={1}
                          >
                            <img
                              src={
                                overAll.result?.result === "PASS"
                                  ? success
                                  : failed
                              }
                              width={"22px"}
                            />
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                pr: "3px",
                              }}
                            >
                              {overAll.result?.result === "PASS"
                                ? "Not fraud"
                                : overAll.result?.result === "NOT_PERFORMED"
                                ? "Failed"
                                : "Fraud"}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                      {overAll.result?.certaintyLevel && <Divider />}
                      {overAll.result?.certaintyLevel && (
                        <Stack direction={"column"} spacing={1}>
                          <Stack
                            spacing={1}
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent="space-between"
                          >
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "16px",
                                color: theme.palette.textGray.main,
                              }}
                            >
                              Certainty Level
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "16px",
                              }}
                            >
                              {overAll.result?.certaintyLevel}
                            </Typography>
                          </Stack>
                          {overAll.result?.result === "PASS" && (
                            <Grid container spacing={0} pb="10px">
                              <Grid item xs={4}>
                                <Box
                                  sx={{
                                    borderRadius: 2,
                                    bgcolor: alpha(
                                      theme.palette.status.approved,
                                      0.4
                                    ),
                                    height: "10px",
                                    mr: 1,
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box
                                  sx={{
                                    borderRadius: 2,
                                    bgcolor:
                                      overAll.result?.certaintyLevel === "LOW"
                                        ? alpha(
                                            theme.palette.textGray.main,
                                            0.5
                                          )
                                        : alpha(
                                            theme.palette.status.approved,
                                            0.7
                                          ),
                                    height: "10px",
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box
                                  sx={{
                                    borderRadius: 2,
                                    bgcolor:
                                      overAll.result?.certaintyLevel !== "HIGH"
                                        ? alpha(
                                            theme.palette.textGray.main,
                                            0.5
                                          )
                                        : alpha(
                                            theme.palette.status.approved,
                                            0.9
                                          ),
                                    height: "10px",
                                    ml: 1,
                                  }}
                                ></Box>
                              </Grid>
                            </Grid>
                          )}
                          {overAll.result?.result !== "PASS" && (
                            <Grid container spacing={0} pb="10px">
                              <Grid item xs={4}>
                                <Box
                                  sx={{
                                    borderRadius: 2,
                                    bgcolor: alpha(
                                      theme.palette.status.rejected,
                                      0.4
                                    ),
                                    height: "10px",
                                    mr: 1,
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box
                                  sx={{
                                    borderRadius: 2,
                                    bgcolor:
                                      overAll.result?.certaintyLevel === "LOW"
                                        ? alpha(
                                            theme.palette.textGray.main,
                                            0.5
                                          )
                                        : alpha(
                                            theme.palette.status.rejected,
                                            0.7
                                          ),
                                    height: "10px",
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box
                                  sx={{
                                    borderRadius: 2,
                                    bgcolor:
                                      overAll.result?.certaintyLevel !== "HIGH"
                                        ? alpha(
                                            theme.palette.textGray.main,
                                            0.5
                                          )
                                        : alpha(
                                            theme.palette.status.rejected,
                                            0.9
                                          ),
                                    height: "10px",
                                    ml: 1,
                                  }}
                                ></Box>
                              </Grid>
                            </Grid>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  )}
                  {/* <Box>
                    <Button
                      variant="contained"
                      disableElevation
                      color="theme"
                      sx={{
                        height: "54px",
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: 2,
                        textTransform: "none",
                      }}
                      fullWidth
                      // disabled={isLoading}
                      onClick={() => setViewMoreSelected(!viewMoreSelected)}
                    >
                      {viewMoreSelected ? "View Less" : "View More"}
                    </Button>
                  </Box> */}
                </Stack>
              </Grid>
              <Grid item md={9}>
                {!viewMoreSelected && (
                  <Grid container spacing={2}>
                    {/* verificationDetails */}
                    {verificationDetails?.map((verificationItem, index) => {
                      return (
                        <Grid item xs={12} md={6} key={index}>
                          <Stack height={"100%"}>
                            <Stack
                              direction={"column"}
                              sx={{
                                bgcolor: theme.palette.common.lightGreyBg,
                                borderRadius: 2,
                                p: 2,
                                py: 1,
                              }}
                              height={"100%"}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent="space-between"
                                p={2}
                                py={2}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "18px",
                                  }}
                                >
                                  {verificationItem.title}
                                </Typography>
                                {verificationItem.status === 1 && (
                                  <Chip
                                    label="Pass"
                                    sx={{
                                      height: "28px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: theme.palette.passGreen.main,
                                      bgcolor: alpha(
                                        theme.palette.passGreen.main,
                                        0.2
                                      ),
                                    }}
                                  />
                                )}
                                {verificationItem.status === 2 && (
                                  <Chip
                                    label="Failed"
                                    sx={{
                                      height: "28px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: theme.palette.status.rejected,
                                      bgcolor: alpha(
                                        theme.palette.status.rejected,
                                        0.2
                                      ),
                                    }}
                                  />
                                )}
                                {verificationItem.status === 3 && (
                                  <Chip
                                    label="Not Performed"
                                    sx={{
                                      height: "28px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                  />
                                )}
                              </Stack>
                              {verificationItem.items.map((detailItem, ind) => {
                                return (
                                  <Fragment key={ind}>
                                    {/* <Accordion */}
                                    {/* //   sx={{
                                //     bgcolor: theme.palette.common.lightGreyBg,
                                //     boxShadow: "none",
                                //     "& .MuiAccordionSummary-expandIcon": {
                                //       display: "none",
                                //     },
                                //   }}
                                //   key={ind}
                                // >
                                //   <AccordionSummary
                                //     // expandIcon={
                                //     //   <ArrowForwardIosSharpIcon
                                //     //     sx={{
                                //     //       width: "15px",
                                //     //       color: theme.palette.textGray.main,
                                //     //     }}
                                //     //   />
                                //     // }
                                //     aria-controls="panel1-content"
                                //     id="panel1-header"
                                //     onClick={() => console.log("")}
                                //   > */}
                                    <Divider />
                                    <Stack
                                      direction={"row"}
                                      py={"15px"}
                                      pl="12px"
                                      spacing={1}
                                    >
                                      {detailItem.status === 1 && (
                                        <img src={success} width={"24px"} />
                                      )}
                                      {detailItem.status === 2 && (
                                        <img src={failed} width={"22px"} />
                                      )}
                                      {detailItem.status === 3 && (
                                        <img
                                          src={notApplicable}
                                          width={"22px"}
                                        />
                                      )}
                                      <Typography
                                        sx={{
                                          fontWeight: 400,
                                          fontSize: "16px",
                                        }}
                                      >
                                        {detailItem.title}
                                      </Typography>
                                    </Stack>
                                    {
                                      /* // </AccordionSummary>
                                /* {detailItem.items?.map((data, ind3) => {
                                    return (
                                      <AccordionDetails key={ind3}>
                                        <Stack
                                          direction={"row"}
                                          pl={4}
                                          spacing={1}
                                        >
                                          {data.status === 1 && (
                                            <img src={success} width={"24px"} />
                                          )}
                                          {data.status === 2 && (
                                            <img src={failed} width={"22px"} />
                                          )}
                                          {data.status === 3 && (
                                            <img
                                              src={notApplicable}
                                              width={"22px"}
                                            />
                                          )}
                                          <Typography
                                            sx={{
                                              fontWeight: 400,
                                              fontSize: "16px",
                                            }}
                                          >
                                            {data.title}
                                          </Typography>
                                        </Stack>
                                      </AccordionDetails>
                                    );
                                  })} */
                                      // </Accordion> */
                                    }
                                  </Fragment>
                                );
                              })}
                            </Stack>
                          </Stack>
                        </Grid>
                      );
                    })}
                    {/* <Grid item sm={12} md={6}>
                      <Stack height={"100%"}>
                        <Stack
                          direction={"column"}
                          sx={{
                            bgcolor: theme.palette.common.lightGreyBg,
                            borderRadius: 2,
                            p: 2,
                          }}
                          height={"100%"}
                        >
                          {documentAndImageData.map((docData, i) => {
                            return (
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent="space-between"
                                p={2}
                                py={1}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "18px",
                                  }}
                                >
                                  {docData.title}
                                </Typography>
                                {docData.status === 1 && (
                                  <Chip
                                    label="Pass"
                                    sx={{
                                      height: "28px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: theme.palette.passGreen.main,
                                      bgcolor: alpha(
                                        theme.palette.passGreen.main,
                                        0.2
                                      ),
                                    }}
                                  />
                                )}
                                {docData.status === 2 && (
                                  <Chip
                                    label="Failed"
                                    sx={{
                                      height: "28px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: theme.palette.status.rejected,
                                      bgcolor: alpha(
                                        theme.palette.status.rejected,
                                        0.2
                                      ),
                                    }}
                                  />
                                )}
                                {docData.status === 3 && (
                                  <Chip
                                    label="Not Performed"
                                    sx={{
                                      height: "28px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                  />
                                )}
                              </Stack>
                            );
                          })}
                        </Stack>
                      </Stack>
                    </Grid> */}
                  </Grid>
                )}
                {viewMoreSelected && (
                  <Stack direction={"column"} spacing={2}>
                    <DynamicKeys jsonObject={statusDetails} />
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Stack>
        )}
      </PageStackLayout>
    </Fragment>
  );
};
