// import axios from "axios";
// import urls from "./urls";
// import { useLocalStorage } from "../hooks";
// import { useAuth } from "../context";

// const ignore = [urls.login, urls.forgotPassword, urls.resetPassword];
// const { apiBaseURL } = useAuth();

// // Create an Axios instance
// const api = axios.create({
//   baseURL: apiBaseURL ? apiBaseURL : urls.baseURL.dev, // Your API base URL. Make sure to check and change this while taking build.
// });

// // Add a request interceptor
// api.interceptors.request.use(
//   (config) => {
//     // You can modify the request config here (e.g., add headers)
//     // For example, you might add an authorization header:
//     // config.headers.Authorization = `Bearer ${yourAuthToken}`;

//     if (!ignore.includes(config.url)) {
//       // Check if there's a token in local storage and if it's expired
//       const { getItem } = useLocalStorage();
//       const accessToken = getItem("access_token");

//       if (accessToken) {
//         // Add the access token to the headers
//         config.headers.Authorization = `Bearer ${accessToken}`;
//       }
//     }

//     return config;
//   },
//   (error) => {
//     // Handle request errors
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// api.interceptors.response.use(
//   (response) => {
//     // You can modify the response data here

//     if (response.status === 200) {
//       const data = response.data.data;
//       const { setItem } = useLocalStorage();
//       if (response.config.url === urls.login) {
//         setItem("access_token", data.access_token);
//         setItem("refresh_token", data.refresh_token);
//         setItem("is_superuser", data.is_superuser);
//         setItem("toppan_name", data.name);
//         setItem("toppan_email", data.email);
//         if (data.role) {
//           setItem("role", JSON.stringify(data.role));
//         }
//       }
//     }

//     return response;
//   },
//   async (error) => {
//     // Return any other errors
//     return Promise.reject(error);
//   }
// );

// export default api;

// useApiConfig.js
import { useMemo } from "react";
import axios from "axios";
import urls from "./urls";
import { useLocalStorage } from "../hooks";
import { useAuth } from "../context";

const useApiConfig = () => {
  const { apiBaseURL } = useAuth();
  const { getItem, setItem, removeItem } = useLocalStorage();

  const ignore = [urls.login, urls.forgotPassword, urls.resetPassword];

  const api = useMemo(() => {
    let baseURL = apiBaseURL || urls.baseURL.ekyc;
    if (baseURL.includes("v1")) {
      baseURL = baseURL.split("v1")[0];
    }

    const instance = axios.create({
      baseURL, // The base URL for the API requests
    });

    // Request interceptor
    instance.interceptors.request.use(
      (config) => {
        if (!ignore.includes(config.url)) {
          const accessToken = getItem("access_token");
          if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Response interceptor
    instance.interceptors.response.use(
      (response) => {
        if (response.status === 200 && response.config.url === urls.login) {
          const data = response.data.data;
          setItem("access_token", data.access_token);
          setItem("refresh_token", data.refresh_token);
          setItem("is_superuser", data.is_superuser);
          setItem("toppan_name", data.name);
          setItem("toppan_email", data.email);
          if (data.role) {
            setItem("role", JSON.stringify(data.role));
          }
        }
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (!ignore.includes(originalRequest.url)) {
          const accessToken = getItem("access_token");
          const refreshToken = getItem("refresh_token");
          if (
            error.response.status === 401 &&
            !originalRequest._retry &&
            refreshToken !== null
          ) {
            originalRequest._retry = true;

            try {
              const response = await instance.post(urls.refreshToken, {
                access_token: accessToken,
                refresh_token: refreshToken,
              });
              const data = response.data.data;
              setItem("access_token", data.access_token);

              originalRequest.headers.Authorization = `Bearer ${data.access_token}`;

              return instance(originalRequest);
            } catch (refError) {
              removeItem("access_token");
              removeItem("refresh_token");
              removeItem("user_data");
              console.error("Error refreshing token:", refError);
              return Promise.reject(refError);
            }
          }
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }, [apiBaseURL, getItem, setItem, removeItem]);

  return api;
};

export default useApiConfig;
